@import '@/assets/css/index.scss';

.expandedWrapper {
    width: 100%;
    position: relative;
    padding: $content-padding;
    background-color: var(--color-white);
    z-index: 1;
    border-radius: 0 0 8px 8px;

    &--noBackground {
        border-radius: unset;
        background-color: unset;
    }

    &--noPadding {
        padding: 0;
    }
}

.expanded {
    width: 100%;
    padding: 4px;
    background-color: var(--color-mySin);
    border-radius: 3px;
}

.inputWrapper {
    @include flex-gap(1px);
    flex-direction: column;
    background-color: var(--color-alto);
    border-radius: 3px 3px 0 0;
    overflow: hidden;
}

.expandedDateConfigContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1px;
    width: 100%;

    &--hasDuration {
        grid-template-columns: 243px 1fr;
    }
}

.ctaWrapper {
    z-index: 2;
}

.cta {
    border-radius: 0 0 5px 5px !important;
}
