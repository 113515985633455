@import '@/assets/css/index.scss';

.travelFormInput {
    @include flex-gap-horizontal($content-padding);
    align-items: center;
    width: 100%;
    padding: 14px 12px;
    background-color: var(--color-white);
    z-index: 1;
    height: 48px;
}

.travelFormValue {
    line-height: 18px;

    &--hasError {
        color: var(--color-persianRed);
    }
}
